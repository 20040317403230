import { React } from "react";
import {
  AboutUs,
  FeaturedProjects,
  Projects,
  Services,
  Clients,
  Contact,
} from "../components";

function Home() {
  return (
    <>
      <AboutUs />
      <FeaturedProjects />
      <Projects />
      <Services />
      <Clients />
      <Contact />
    </>
  );
}

export default Home;
