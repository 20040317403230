import React from "react";

import {
  createRoutesFromElements,
  Navigate,
  Route,
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom";
import {
  Home,
  AboutPage,
  ProjectsPage,
  ContactPage,
  ProjectDetails,
  ProjectsByCategory,
  ServicesPage,
  SingleService,
} from "./pages";
import RootLayout from "./layout/RootLayout";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<RootLayout />}>
      <Route path="/" element={<Home />} />
      <Route path="/about" element={<AboutPage />} />
      <Route path="/projects" element={<ProjectsPage />} />
      <Route path="/projects/:category" element={<ProjectsByCategory />} />
      <Route path="/projects/:category/:title" element={<ProjectDetails />} />
      <Route path="/services" element={<ServicesPage />} />
      <Route path="/services/:title" element={<SingleService />} />
      <Route path="/contact" element={<ContactPage />} />
      <Route path="*" element={<Navigate to="/" />} />
    </Route>
  )
);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
