import React from "react";
import { useEffect, useState } from "react";
import { BiArrowFromBottom } from "react-icons/bi";
import { useLocation } from "react-router-dom";

function ScrollToTop() {
  const [isVisible, setIsVisible] = useState(false);
  const { pathname, hash } = useLocation();

  const toggleVisibility = () =>
    setIsVisible(window.pageYOffset > 300 ? true : false);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);

    return () => {
      window.removeEventListener("scroll", toggleVisibility);
    };
  }, []);

  useEffect(() => {
    if (!hash) scrollToTop();
  }, [pathname, hash]);

  return (
    <div className="fixed bottom-2 right-2">
      <button
        type="button"
        onClick={scrollToTop}
        className={`${
          isVisible ? "opacity-100" : "opacity-0"
        } bg-yellow-400 mr-2 mb-2 hover:bg-yellow-500 focus:ring-yellow-300 inline-flex items-center rounded-full p-3 text-white shadow-sm transition-all focus:outline-none focus:ring-2 focus:ring-offset-2`}
      >
        <BiArrowFromBottom className="h-6 w-6" aria-hidden="true" />
      </button>
    </div>
  );
}
export default ScrollToTop;
