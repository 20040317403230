export const CLIENTS = [
  {
    title: "Minister Of Military Production",
    img: "/images/clients/momp.webp",
  },
  {
    title: "Egyptian Armed Forces Engineering Authority",
    img: "/images/clients/eafea.webp",
  },
  {
    title: "New Urban Authority",
    img: "/images/clients/nua.webp",
  },
  {
    title: "Wadi El Nile For Contracting & Real Estate Investments",
    img: "/images/clients/wenfcrei.webp",
  },
  {
    title: "General Organization For Physical Planning",
    img: "/images/clients/gofpp.webp",
  },
  {
    title: "Nasr General Contracting Co. European Shola School",
    img: "/images/clients/ngccess.webp",
  },
  {
    title: "The Arab Contractors",
    img: "/images/clients/tac.webp",
  },
  {
    title: "Misr Consult",
    img: "/images/clients/mc.webp",
  },
  {
    title: "Home Insurance Agency In Cairo",
    img: "/images/clients/hiaic.webp",
  },
  {
    title: "Engineering facilities of Zagazig University",
    img: "/images/clients/efozu.webp",
  },
  {
    title: "Misr Concrete Development Co",
    img: "/images/clients/mcdc.webp",
  },
  {
    title: "Sherkaet Matahen & Makhabez Ganoub Al Kahera & El Giza",
    img: "/images/clients/smmgakeg.webp",
  },
  {
    title: "Canal For General Contracting And Supplies",
    img: "/images/clients/cfgcas.webp",
  },
  {
    title: "General Authority For Health Insurance",
    img: "/images/clients/gafhi.webp",
  },
  {
    title: "Investors Association",
    img: "/images/clients/ia.webp",
  },
  {
    title: "10th Of Ramadan Investor Association",
    img: "/images/clients/10thoria.webp",
  },
  {
    title: "Ministry Of Agriculture And Land Reclamation",
    img: "/images/clients/moaalr.webp",
  },
  {
    title: "General Nile Company For Desert Roads",
    img: "/images/clients/gncfdr.webp",
  },
  {
    title: "Al-Qahera For Contacting And General Supplies",
    img: "/images/clients/aqfcags.webp",
  },
  {
    title: "Giza General Contracting",
    img: "/images/clients/ggc.webp",
  },
];
