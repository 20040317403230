import { React } from "react";
import BlurLoad from "./BlurLoad";

function FeaturedProjects() {
  return (
    <section>
      <h2 className="mb-5 text-center uppercase font-bold font-[Aquarium] text-[clamp(2rem,3vw,4rem)]">
        featured projects
      </h2>
      <div className="bg-center bg-fixed bg-cover bg-no-repeat flex-col py-10  bg-[linear-gradient(rgba(4,9,30,0.3),rgba(4,9,30,0.5)),url('~/public/images/featured-projects-bg.webp')]">
        <div className="flex max-w-[90%] container m-auto justify-between rounded-xl bg-white items-center overflow-hidden">
          <div className="flex flex-col lg:w-[70%] px-5">
            <h2 className="text-center text-black uppercase font-bold text-[clamp(2rem,3vw,3rem)]  font-[Aquarium] my-5">
              Tohshka Project
            </h2>
            <p className="text-gray-500  max-lg:text-center text-[18px] max-lg:text-[12px] text-center">
              Toshka Village, Sahara Desert of Egypt Location: New Valley
              Governorate Description: 720,000 thousand Acres, were responsible
              for branch one, and 25 Industrial plant, and the length of the
              canal is 32,400 KM.
            </p>
            <a
              className="text-center font-semibold capitalize mx-auto w-32 py-2 my-6 block bg-white border-black hover:invert rounded-lg border-2 transition-all"
              href="/projects/land-reclamation"
            >
              View more
            </a>
          </div>
          <BlurLoad
            div={{
              cls: "flex items-center h-[580px] w-full max-lg:hidden bg-[url('~/public/images/featured-projects-small.png')] blur-load",
            }}
            img={{
              src: "/images/featured-projects.webp",
              alt: "toshka",
              cls: "h-full",
            }}
          />
        </div>
      </div>
    </section>
  );
}
export default FeaturedProjects;
