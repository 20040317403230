import { React, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { PROJECTS } from "../constant/Projects";

function ProjectsByCategory() {
  const [projects, setProjects] = useState([]);
  const [isFeatured, setIsFeatured] = useState(false);
  const { category } = useParams();

  useEffect(() => {
    let tempProjects = PROJECTS.find(
      ({ url }) => url.toLowerCase().trim() === category.toLowerCase().trim()
    );
    setIsFeatured(tempProjects.featured);
    setProjects(tempProjects.projects.sort((a, b) => b.year - a.year));
  }, [category]);

  useEffect(() => {
    const blurLoad = document.querySelectorAll(".blur-load");
    blurLoad.forEach((e) => {
      const img = e.querySelector("img");

      function loadded() {
        e.classList.add("loadded");
      }

      img.complete ? loadded() : img.addEventListener("load", loadded);
    });
  }, [projects]);

  return (
    <section className="py-12">
      {isFeatured ? (
        <div className="container flex items-center justify-evenly m-auto flex-wrap gap-5 px-2">
          <a
            href={`${category}/${projects[0].url}`}
            className={`group w-[600px] sm:h-[338px] h-[250px] blur-load ${projects[0].bgImg[0]} m-auto text-center relative overflow-hidden  cursor-pointer`}
            data-aos="fade-up"
          >
            <img
              src={projects[0].img[0]}
              alt={projects[0].title}
              className="h-full max-md:brightness-50 group-hover:brightness-50 transition-all"
              loading="lazy"
            />
            <h2 className="absolute max-md:underline -bottom-[50%] text-sm left-[50%] -translate-x-[50%] md:group-hover:bottom-[50%] md:group-hover:left-[50%] md:group-hover:-translate-x-[50%] md:group-hover:translate-y-[50%] md:group-hover:scale-150 max-md:bottom-[50%] max-md:left-[50%] max-md:-translate-x-[50%] max-md:translate-y-[50%] max-md:scale-150  transition-all  uppercase text-white duration-[.5s] font-semibold">
              {projects[0].title}
            </h2>
          </a>
          <hr className="bg-gray-500 h-1 w-full" />
          <div className="flex justify-evenly items-center gap-5 overflow-hidden">
            <div
              className="flex flex-col max-lg:text-center p-5"
              data-aos="zoom-in"
              data-aos-delay="500"
            >
              <p className="leading-8 text-black font-bold text-[18px] text-left">
                A study of the reclamation and rehabilitation of 13,800 acres
                was commissioned by the World Bank in the following Villages:
              </p>
              <ul className="flex flex-col gap-3 md:ml-5 font-normal text-left mt-5">
                {projects[0].info.map((item, index) => (
                  <li key={index} className="flex gap-2 items-center">
                    <span className="bg-gray-500 rounded text-center w-8 h-8 flex items-center p-2 justify-center font-normal text-white font-[Aquarium]">
                      {index + 1}
                    </span>
                    {item}
                  </li>
                ))}
              </ul>
            </div>
            <div
              className="blur-load w-[900px] h-[400px] max-lg:hidden bg-[url('~/public/images/projects/toshka05-small.png')]"
              data-aos="fade-left"
              data-aos-delay="600"
            >
              <img
                className="h-full"
                src="/images/projects/toshka05.webp"
                alt="toshka"
                loading="lazy"
              />
            </div>
          </div>
        </div>
      ) : (
        <div className="grid grid-cols-[repeat(auto-fit,minmax(250px,300px))] justify-center gap-4 max-w-[calc(298px*4)] mx-auto">
          {projects.map((project, index) => (
            <a
              key={index}
              href={`${category}/${project.url}`}
              className={`group text-center relative overflow-hidden blur-load ${project?.bgImg} h-[420px] cursor-pointer shadow-[5px_5px_5px_5px_#b4b4b4]`}
              data-aos="fade-up"
              data-aos-delay={index * 100}
            >
              <img
                src={project.img}
                alt={project.title}
                className="h-full max-md:brightness-50 group-hover:brightness-50 transition-all"
                loading="lazy"
              />
              <h2 className="absolute -bottom-[50%] max-md:underline text-sm left-[50%] -translate-x-[50%] md:group-hover:bottom-[50%] md:group-hover:left-[50%] md:group-hover:-translate-x-[50%] md:group-hover:translate-y-[50%] md:group-hover:scale-150 max-md:bottom-[50%] max-md:left-[50%] max-md:-translate-x-[50%] max-md:translate-y-[50%] max-md:scale-150  transition-all  uppercase text-white duration-[.5s] font-semibold">
                {project.title}
              </h2>
            </a>
          ))}
        </div>
      )}
    </section>
  );
}

export default ProjectsByCategory;
