import React from "react";
import { useEffect, useRef } from "react";
import { useLocation, NavLink } from "react-router-dom";

function Navbar() {
  const { pathname } = useLocation();
  const menu = useRef();

  useEffect(() => {
    let isHidden = Array.from(menu.current.classList).includes("hidden");
    if (!isHidden) menu.current.classList.add("hidden");
  }, [pathname]);

  return (
    <nav className="p-4 bg-[rgb(0,0,0,.7)] fixed z-10 w-full">
      <div className="container flex flex-wrap items-center justify-between mx-auto relative">
        <NavLink to="/" className="w-28">
          <img
            src="/images/logo.webp"
            className="mr-3 w-full"
            alt="logo"
            loading="lazy"
          />
        </NavLink>
        <button
          data-collapse-toggle="navbar-dropdown"
          type="button"
          className="inline-flex p-2 transition text-white rounded-lg md:hidden hover:bg-[rgb(255,255,255,.1)]"
          onClick={() => menu.current.classList.toggle("hidden")}
        >
          <svg
            className="w-6 h-6"
            aria-hidden="true"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
              clipRule="evenodd"
            ></path>
          </svg>
        </button>
        <div
          className="w-full md:block md:w-auto absolute top-[40px] md:static z-10 "
          id="navbar-dropdown"
          ref={menu}
        >
          <ul className="flex flex-col p-4 mt-[2.5rem]  rounded-lg bg-[rgb(0,0,0,.7)] md:flex-row md:space-x-8 md:mt-0 md:text-sm md:font-medium md:border-0 md:bg-transparent">
            <li>
              <NavLink
                to="/"
                className="text-[#ffffff80] hover:text-[#f5cc00] aria-[current]:text-[#f5cc00] transition block py-2 pl-3 pr-4 md:p-0 text-[18px]"
                aria-current="page"
              >
                Home
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/about"
                className="text-[#ffffff80] hover:text-[#f5cc00] aria-[current]:text-[#f5cc00] transition block py-2 pl-3 pr-4 md:p-0 text-[18px]"
              >
                About Us
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/services"
                className="text-[#ffffff80] hover:text-[#f5cc00] aria-[current]:text-[#f5cc00] transition block py-2 pl-3 pr-4 md:p-0 text-[18px]"
              >
                Services
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/projects"
                className="text-[#ffffff80] hover:text-[#f5cc00] aria-[current]:text-[#f5cc00] transition block py-2 pl-3 pr-4 md:p-0 text-[18px]"
              >
                Projects
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/contact"
                className=" text-[#ffffff80] hover:text-[#f5cc00] aria-[current]:text-[#f5cc00] transition block py-2 pl-3 pr-4 md:p-0 text-[18px]"
              >
                Contact Us
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}
export default Navbar;
