import React, { useEffect, useRef } from "react";
import { SERVICES } from "../constant/Services";
import { useLocation } from "react-router-dom";
import { BlurLoad } from "../components";

function ServicesPage() {
  const servicesRef = useRef();
  const { hash } = useLocation();

  useEffect(() => {
    servicesRef.current?.scrollIntoView({
      behavior: "smooth",
    });
  }, []);

  return (
    <div className="container py-16 mx-auto flex flex-col gap-20">
      {SERVICES.map((service, index) => (
        <div
          key={index}
          className={`${
            index & 1 ? "lg:flex-row" : "lg:flex-row-reverse"
          } flex items-center gap-10 flex-col p-5 overflow-hidden justify-center`}
          id={service.id}
          ref={
            hash.slice(1).toLowerCase().trim() ===
            service.id.toLowerCase().trim()
              ? servicesRef
              : null
          }
        >
          <BlurLoad
            div={{
              cls: `blur-load ${service.bgImg} sm:h-[70vh] h-[50vh] w-full max-w-[500px]`,
              aos: {
                type: "flip-left",
                duration: "1500",
                easing: "ease-out-cubic",
              },
            }}
            img={{
              src: service.img,
              alt: service.title,
              cls: "h-full",
            }}
          />
          <div
            className="info flex-col flex gap-4 max-w-[800px] lg:w-1/2"
            data-aos="zoom-in-up"
            data-aos-easing="liner"
            data-aos-duration="1000"
          >
            <h2 className="font-bold text-2xl">{service.title}</h2>
            <p>{service.content}</p>
            {service.more && (
              <a
                className="text-center font-semibold capitalize max-lg:mx-auto w-32 py-2 hover:invert bg-white block rounded-lg border-2 border-black transition-all"
                href={`/services/${service.id}`}
              >
                Read more
              </a>
            )}
          </div>
        </div>
      ))}
    </div>
  );
}

export default ServicesPage;
