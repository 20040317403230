import { React } from "react";
import { PROJECTS } from "../constant/Projects";

function Projects() {
  return (
    <section className="py-12">
      <h2 className="text-center uppercase font-bold text-[clamp(2rem,3.5vw,4rem)] mb-[20px] font-[Aquarium]">
        Projects
      </h2>
      <div className="container m-auto">
        <div className="grid grid-cols-[repeat(auto-fill,minmax(370px,1fr))] max-[450px]:grid-cols-[repeat(auto-fill,minmax(250px,1fr))] px-5 gap-4">
          {PROJECTS.slice(0, 6).map((project, index) => (
            <a
              key={`PR-${index}`}
              href={`/projects/${project.url}`}
              className={`rounded-2xl shadow-[5px_5px_5px_5px_#b4b4b4] group text-center relative overflow-hidden h-[300px] cursor-pointer blur-load ${project.bgImg} before:content-[''] before:absolute before:top-0 before:left-0 before:w-full before:h-full hover:before:opacity-50 before:bg-black before:opacity-50 before:transition-all`}
              data-aos="fade-up"
              data-aos-delay={index * 100}
            >
              <img
                src={project.img}
                alt={project.category}
                loading="lazy"
                className="h-full"
              />
              <h2 className="absolute bottom-3 text-sm left-3 md:group-hover:bottom-[50%] md:group-hover:left-[50%] md:group-hover:-translate-x-[50%] md:group-hover:translate-y-[50%] md:group-hover:scale-150 max-md:bottom-[50%] max-md:left-[50%] max-md:-translate-x-[50%] max-md:translate-y-[50%] max-md:scale-150 transition-all  uppercase text-white duration-[.5s] font-semibold">
                {project.category}
              </h2>
            </a>
          ))}
        </div>
        <a
          className="text-center font-semibold capitalize mx-auto mt-10 w-32 py-2 hover:invert bg-white block rounded-lg border-2 border-black transition-all"
          type="button"
          href="/projects"
        >
          View All
        </a>
      </div>
    </section>
  );
}

export default Projects;
