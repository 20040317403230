import React from "react";
import { BsLinkedin, BsFillTelephoneFill } from "react-icons/bs";
import { TbDeviceLandlinePhone } from "react-icons/tb";
import { HiMail } from "react-icons/hi";
import { toast } from "react-toastify";

function Footer() {
  const notify = () =>
    toast.info("Copied to clipboard", {
      position: "bottom-right",
      autoClose: 2500,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });

  function copyToClipboard(e) {
    navigator.clipboard
      .writeText(e.target.parentElement.innerText)
      .then(() => notify());
  }

  return (
    <footer className="bg-[#24262b]">
      <div className="w-full max-w-screen-xl mx-auto p-4 md:py-8">
        <div className="flex flex-col gap-5">
          <div className="flex justify-between max-md:flex-col max-md:gap-5">
            <div className="flex items-center max-md:justify-center">
              <img
                src="/images/logo.webp"
                alt="logo"
                className="h-14"
                loading="lazy"
              />
            </div>
            <ul className="col-span-2 flex gap-3 flex-wrap max-md:justify-center items-center text-sm font-medium text-gray-500">
              <li>
                <a
                  href="/about"
                  className="hover:underline capitalize font-semibold"
                >
                  about us
                </a>
              </li>
              <li>
                <a
                  href="/services"
                  className="hover:underline capitalize font-semibold"
                >
                  services
                </a>
              </li>
              <li>
                <a
                  href="/projects"
                  className="hover:underline capitalize font-semibold"
                >
                  projects
                </a>
              </li>
              <li>
                <a
                  href="/contact"
                  className="hover:underline capitalize font-semibold"
                >
                  contact us
                </a>
              </li>
            </ul>
          </div>
          <span className="block text-sm text-gray-500 sm:text-center font-bold max-md:text-center">
            © DR. Hamdy Shehab . All Rights Reserved.
          </span>
        </div>
        <hr className="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" />
        <div className="flex justify-center">
          <ul className="flex flex-wrap items-center text-sm font-medium gap-3 text-gray-500 justify-center">
            <li>
              <button
                className="text-lg gap-2 flex items-center"
                onClick={(e) => copyToClipboard(e)}
              >
                <TbDeviceLandlinePhone />
                0222906226
              </button>
            </li>
            <li>
              <button
                className="text-lg gap-2 flex items-center"
                onClick={(e) => copyToClipboard(e)}
              >
                <HiMail />
                Drhamdyshehab@outlook.com
              </button>
            </li>
            <li>
              <button
                className="text-lg gap-2 flex items-center"
                onClick={(e) => copyToClipboard(e)}
              >
                <BsFillTelephoneFill />
                +201210276274
              </button>
            </li>
            <li>
              <a
                href="https://www.linkedin.com/company/dr-hamdy-shehab-engineering-consultant/"
                target="_blank"
                className="text-lg gap-2 flex items-center"
                rel="noreferrer"
              >
                <BsLinkedin />
                Linkedin
              </a>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
