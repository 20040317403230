import React from "react";
import { FaQuoteLeft, FaQuoteRight } from "react-icons/fa";
import { BlurLoad } from "../components";
function About() {
  function showMore(e) {
    e.target.nextSibling.classList.toggle("max-md:hidden");
    e.target.previousSibling.classList.toggle("max-md:hidden");
    e.target.nextSibling.nextSibling.classList.toggle("hidden");
    e.target.classList.toggle("hidden");
  }

  function showLess(e) {
    e.target.previousSibling.classList.toggle("max-md:hidden");
    e.target.previousSibling.previousSibling.classList.toggle("hidden");
    e.target.previousSibling.previousSibling.previousSibling.classList.toggle(
      "max-md:hidden"
    );
    e.target.classList.toggle("hidden");
  }

  return (
    <section>
      <div className="flex justify-evenly items-center container mx-auto gap-5 py-20">
        <article
          className="max-lg:text-center px-5 lg:w-[50%]"
          data-aos="zoom-in"
          data-aos-duration="1000"
        >
          <p className="leading-[30px] font-[500] text-lg text-black">
            <FaQuoteLeft className="inline -translate-x-2 -translate-y-2" />
            Welcome to Dr. Hamdy Shehab With our expert engineering
            consultation, we aim to benefit the whole of society,
            <br /> by being the safer, cleaner, and more efficient foundations
            with our project management skills, and our strong interpersonal.
            <FaQuoteRight className="inline translate-x-2 translate-y-1" />
          </p>
        </article>
        <div className="flex justify-between w-[50%] max-lg:hidden">
          <BlurLoad
            div={{
              cls: "blur-load w-[48%] h-[500px] max-xl:h-[350px] shadow-[5px_5px_5px_5px_#b4b4b4] relative top-10 bg-[url('~/public/images/about/aboutus1-small.png')]",
              aos: { type: "fade-up", dealy: "400" },
            }}
            img={{
              src: "/images/about/aboutus1.webp",
              alt: "about us",
              cls: "h-full",
            }}
          />
          <BlurLoad
            div={{
              cls: "blur-load w-[48%] h-[500px] max-xl:h-[350px] shadow-[5px_5px_5px_5px_#b4b4b4] bg-[url('~/public/images/about/aboutus2-small.png')]",
              aos: { type: "fade-down", delay: "600" },
            }}
            img={{
              src: "/images/about/aboutus2.webp",
              alt: "about us",
              cls: "h-full",
            }}
          />
        </div>
      </div>
      <div className="bg-center bg-cover bg-no-repeat h-full py-16 w-full relative blur-load bg-[url('~/public/images/about/banner2-small.png')]">
        <img
          src="/images/about/banner2.webp"
          alt="contactus"
          className="h-full absolute top-0 left-0 brightness-50"
        />
        <div className="relative z-[1] text-white">
          <h2 className="text-[clamp(2rem,3.5vw,5rem)] font-[Aquarium] font-bold text-center ">
            Our History
          </h2>
          <blockquote className="text-2xl italic font-medium text-center px-5 text-[clamp(1rem,1.5vw,5rem)]">
            <svg
              aria-hidden="true"
              className="w-12 h-12 mx-auto my-3"
              viewBox="0 0 24 27"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.017 18L14.017 10.609C14.017 4.905 17.748 1.039 23 0L23.995 2.151C21.563 3.068 20 5.789 20 8H24V18H24.017ZM0 18V10.609C0 4.905 3.748 1.038 9 0L9.996 2.151C7.563 3.068 6 5.789 6 8H9.983L9.983 18L0 18Z"
                fill="currentColor"
              />
            </svg>
            "Hamdy Shehab founded a consulting engineering office in 1994, which
            has become a trusted leader in the industry. Initially providing
            traditional engineering services, the office expanded its expertise
            to include new areas."
          </blockquote>
        </div>
      </div>
      <div>
        <div className="flex justify-evenly items-center ">
          <BlurLoad
            div={{
              cls: "blur-load bg-[url('~/public/images/about/mission-small.png')] w-[50%] max-lg:hidden h-[600px]",
              aos: { delay: "200", type: "fade-right" },
            }}
            img={{
              alt: "mission",
              src: "/images/about/mission.webp",
              cls: "h-full",
            }}
          />
          <div
            className="flex flex-col max-lg:text-center p-5 lg:w-[50%]"
            data-aos="zoom-in"
            data-aos-delay="200"
          >
            <h2 className="text-[clamp(1.7rem,3.2vw,5rem)]  font-[Aquarium] font-bold">
              mission
            </h2>
            <p className="leading-8 text-gray-500 ">
              At our engineering consultancy office, we strive to provide our
              clients with customized engineering solutions that are efficient,
              sustainable, and innovative.
              <span className="md:hidden">...</span>
              <button
                className="text-black font-bold md:hidden hover:underline"
                onClick={(e) => showMore(e)}
              >
                Read more
              </button>
              <span className="max-md:hidden">
                We are committed to fostering strong partnerships with our
                clients, working closely with them to understand their needs,
                goals, and challenges. Our team of experts is dedicated to
                excellence, professionalism, and ethical behavior, delivering
                high-quality solutions that exceed expectations and drive
                growth.
              </span>
              <button
                className="text-black font-bold md:hidden hidden hover:underline"
                onClick={(e) => showLess(e)}
              >
                Read Less
              </button>
            </p>
          </div>
        </div>
        <div className="flex justify-evenly items-center overflow-hidden">
          <div
            className="flex flex-col max-lg:text-center p-5 lg:w-[50%]"
            data-aos="zoom-in"
            data-aos-delay="500"
          >
            <h2 className="text-[clamp(1.7rem,3.2vw,5rem)] font-[Aquarium] font-bold max-md:text-center">
              vision
            </h2>
            <p className="leading-8 text-gray-500">
              Our vision is to become the leading engineering consultancy office
              in the industry, known for our expertise, innovation, and
              customer-centric approach. <span className="md:hidden">...</span>
              <button
                className="text-black font-bold md:hidden hover:underline"
                onClick={(e) => showMore(e)}
              >
                Read more
              </button>
              <span className="max-md:hidden">
                We aspire to create a culture of continuous improvement,
                investing in our people and resources to stay ahead of the curve
                and deliver cutting-edge solutions that make a positive impact
                on the world. Our goal is to leverage our expertise and
                capabilities to help our clients achieve their goals, while
                promoting sustainability, diversity, and social responsibility.
              </span>
              <button
                className="text-black font-bold md:hidden hidden hover:underline"
                onClick={(e) => showLess(e)}
              >
                Read Less
              </button>
            </p>
          </div>
          <BlurLoad
            div={{
              cls: "blur-load bg-[url('~/public/images/about/vision-small.png')] w-[50%] max-lg:hidden h-[600px]",
              aos: { delay: "200", type: "fade-left" },
            }}
            img={{
              alt: "vision",
              src: "/images/about/vision.webp",
              cls: "h-full",
            }}
          />
        </div>
      </div>
    </section>
  );
}

export default About;
