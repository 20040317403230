export const PROJECTS = [
  {
    category: "Land Reclamation",
    url: "land-reclamation",
    img: "/images/projects/landreclamation.webp",
    bgImg: "bg-[url('~/public/images/projects/landreclamation-small.png')]",
    featured: true,
    projects: [
      {
        title: "Toshka Village, Sahara Desert of Egypt",
        url: "toshka-village-sahara-desert-of-egypt",
        img: [
          "/images/projects/toshka01.webp",
          "/images/projects/toshka02.webp",
          "/images/projects/toshka03.webp",
          "/images/projects/toshka04.webp",
          "/images/projects/toshka05.webp",
        ],
        bgImg: [
          "bg-[url('~/public/images/projects/toshka01-small.png')]",
          "bg-[url('~/public/images/projects/toshka02-small.png')]",
          "bg-[url('~/public/images/projects/toshka03-small.png')]",
          "bg-[url('~/public/images/projects/toshka04-small.png')]",
          "bg-[url('~/public/images/projects/toshka05-small.png')]",
        ],
        info: [
          "10th Village, South of Port Said",
          "Radwan Village, South of Port Said",
          "Tarke Bin Ziyad Village, South of Sahal Al-husaynih, Sharqia Government.",
          "Alsalah Village, South of Sahal Al-husaynih, Sharqia Government.",
          "Alaizdihar Village, South of Sahal Al-husaynih, Sharqia Government.",
        ],
        description: {
          info: "720,000 thousand Acres, were responsible for branch one, and 25 Industrial plant, and  the length of the canal is 32,400 KM.",
          subInfo: [
            "2 Regulators",
            "12 Intakes",
            "2 Bridges",
            "5 Wheels",
            "2 Calvar",
            "End of existing branch one.",
            "Quality control for all the concrete of the project.",
          ],
        },
      },
    ],
  },
  {
    category: "Building Design: Engineering & Planning",
    url: "building-design-engineering-&-planning",
    img: "/images/projects/design-and-building.webp",
    bgImg: "bg-[url('~/public/images/projects/design-and-building-small.png')]",
    featured: false,
    projects: [
      {
        title: "125 Residential Buildings",
        url: "125-residential-buildings",
        img: "/images/projects/125-residential-buildings.webp",
        bgImg:
          "bg-[url('~/public/images/projects/125-residential-buildings-small.png')]",
        partner: "New Urban Communities Authority",
        year: "2018",
        location: "Third Settlement, New Cairo, Cairo Government",
        description: "430 M<sup>2</sup>, Ground, 5 floors",
      },
      {
        title: "Residential Building",
        url: "residential-building",
        img: "/images/projects/residential-building.webp",
        bgImg:
          "bg-[url('~/public/images/projects/residential-building-small.png')]",
        partner: "Military Production.",
        year: "2019.",
        location: "Tanta City, Gharbia Government.",
        description:
          "Supervising on implementation of 3 buildings for Housing buildings for students of Tanta University (Each Building consists of a basement, Ground, and 10 floors.",
      },
      {
        title: "150 Residential Housing Dahshur Project",
        url: "1185-residential-housing-dahshur-project",
        img: "/images/projects/1185-residentioal-housing.webp",
        bgImg:
          "bg-[url('~/public/images/projects/1185-residentioal-housing-small.png')]",
        partner: "Armed Forces.",
        year: "2017.",
        location: "6th of October City, Dahshur City, Giza Government.",
        description:
          "Meter2, Design review, and implementation supervision for 150 social housing buildings of the project, consisting of 5 floors. ",
      },
      {
        title: "48 Residential Buildings",
        url: "48-residential-buildings",
        img: "/images/projects/48-residential-buildings.webp",
        bgImg:
          "bg-[url('~/public/images/projects/48-residential-buildings-small.png')]",
        partner: "Army Force.",
        year: "2020.",
        location: "Ras El-bar, Damietta Government.",
        description: "700 M<sup>2</sup>, ground, with 5 floors.",
      },
      {
        title: "11 Residential Commercial Towers",
        url: "11-residential-commercial-towers",
        img: "/images/projects/11-residential-commercial-towers.webp",
        bgImg:
          "bg-[url('~/public/images/projects/11-residential-commercial-towers-small.png')]",
        partner: "Armed Forces.",
        year: "2020.",
        location: "East Mansoura Government.",
        description: "980 M<sup>2</sup>, basement, Ground, 10 Floors.",
      },
      {
        title: "13 Administrative, Commercial, and Residential Towers",
        url: "13-administrative-commercial-and-residential-towers",
        img: "/images/projects/13-administrative-commercial-and-residential-towers.webp",
        bgImg:
          "bg-[url('~/public/images/projects/13-administrative-commercial-and-residential-towers-small.png')]",
        partner: "Masakan Sandub.",
        year: "2019.",
        location: "Mansoura Government.",
        description: "1000 M<sup>2</sup>, 2 Basements, Ground, with 11 floors.",
      },
      {
        title: "7 Residential Commercial Towers",
        url: "7-residential-commercial-towers",
        img: "/images/projects/7-residential-commercial-towers.webp",
        bgImg:
          "bg-[url('~/public/images/projects/7-residential-commercial-towers-small.png')]",
        partner: "Armed Forces.",
        year: "2022.",
        location: "El-Galaa, El Mansoura 1, Dakahlia Government.",
        description: "980 Meters2, Basement, Ground, with 10 floors.",
      },
      {
        title: "2 Residential Towers",
        url: "2-residential-towers",
        img: "/images/projects/projects-logo.webp",
        bgImg: "bg-[url('~/public/images/projects/projects-logo-small.png')]",
        partner: "Military Production.",
        year: "2020.",
        location: "Islamic Mission City, Daher, Cairo government.",
        description:
          "700 M<sup>2</sup>, Designing and supervising the project, each building having a ground, and 5 floors.",
      },
      {
        title: "Beni Suef Complex for Al-Azhar Administration",
        url: "beni-suef-complex-for-al-azhar-administration",
        img: "/images/projects/projects-logo.webp",
        bgImg: "bg-[url('~/public/images/projects/projects-logo-small.png')]",
        partner: "Military Production.",
        year: "2019.",
        location: "Beni Suef Government.",
        description:
          "700 M<sup>2</sup>, Designing and supervising the project, consisting of Ground, 7 floors.",
      },
      {
        title: "Qanat Al Suez Tower",
        url: "Qanat-Al-Suez-Tower",
        img: "/images/projects/projects-logo.webp",
        bgImg: "bg-[url('~/public/images/projects/projects-logo-small.png')]",
        partner: "Arab Real Estate Investment Company.",
        year: "2005.",
        location: "Mansoura Government.",
        description: "700 M<sup>2</sup>, Basement, Ground, with 11 floors.",
      },
      {
        title: "Armed Forces Hotel",
        url: "Armed-Forces-Hotel",
        img: "/images/projects/projects-logo.webp",
        bgImg: "bg-[url('~/public/images/projects/projects-logo-small.png')]",
        partner: "Armed Forces",
        year: "2018",
        location: "Front of Tiba Mall, Nasr Road, Nasr City, Cairo Government.",
        description: "Supervising the consolidation and development.",
      },
      {
        title: "Commercial Tower",
        url: "Commercial-Tower",
        img: "/images/projects/projects-logo.webp",
        bgImg: "bg-[url('~/public/images/projects/projects-logo-small.png')]",
        partner: "Beaa El-masnoaat.",
        year: "1993.",
        description: "600 M<sup>2</sup>, Ground, with 6 floors.",
      },
      {
        title: "Makka Administrative Mall",
        url: "Makka-Administrative-Mall",
        img: "/images/projects/projects-logo.webp",
        bgImg: "bg-[url('~/public/images/projects/projects-logo-small.png')]",
        partner: "Mr. Fathy Hassan.",
        year: "2006.",
        location: "10th of Ramadan City, Sharqia Government.",
        description: "1800 Meter2, Ground, with 4 floors.",
      },
      {
        title: "Residential Commercial Mall",
        url: "Residential-Commercial-Mall",
        img: "/images/projects/projects-logo.webp",
        bgImg: "bg-[url('~/public/images/projects/projects-logo-small.png')]",
        partner: "Beaa El-masnoaat.",
        year: "2009.",
        location: "Tanta Government.",
        description:
          "2000 M<sup>2</sup>, 2 Basements, Ground, 3 floors Commercial, 7 floors",
      },
      {
        title: "Residential Commercial Malls",
        url: "Residential-Commercial-Malls",
        img: "/images/projects/projects-logo.webp",
        bgImg: "bg-[url('~/public/images/projects/projects-logo-small.png')]",
        partner: "Mr. Osama Hamed.",
        year: "2004.",
        location: "El Kothar, Hurghada Government.",
        description: "1200 M<sup>2</sup>, 2 Basements, Ground, with 4 floors.",
      },
      {
        title: "Administrative Mall",
        url: "Administrative-Mall",
        img: "/images/projects/projects-logo.webp",
        bgImg: "bg-[url('~/public/images/projects/projects-logo-small.png')]",
        partner: "Mr. Salah Abdullah.",
        year: "2011.",
        location: "Shehab St., Mohandseen, Cairo Government.",
        description: "650 M<sup>2</sup>, 2 Basements, Ground, 11 floors.",
      },
      {
        title: "Residential tower",
        url: "Residential-tower",
        img: "/images/projects/projects-logo.webp",
        bgImg: "bg-[url('~/public/images/projects/projects-logo-small.png')]",
        partner: "Eng. Reda Ismail.",
        year: "1998.",
        location: "El Rekaba El Edareya, Nasr City, Cairo Government.",
        description: "500 M<sup>2</sup>, Basement, Ground, with 11 floors.",
      },
      {
        title: "Residential tower",
        url: "Residential-tower",
        img: "/images/projects/projects-logo.webp",
        bgImg: "bg-[url('~/public/images/projects/projects-logo-small.png')]",
        partner: "Eng. Magdy Elhawarii.",
        year: "1995.",
        location: "Passport, Dakahlia Government.",
        description: "400 M<sup>2</sup>, Ground with 9 floors.",
      },
      {
        title: "Residential Tower",
        url: "Residential-Tower",
        img: "/images/projects/projects-logo.webp",
        bgImg: "bg-[url('~/public/images/projects/projects-logo-small.png')]",
        partner: "Eng. Sabry Abdelmonem.",
        year: "1994.",
        location: "Al-Sayeda Zainab front Dar Al-Hlal, Cairo Government.",
        description: "800 Meter2, Ground, with 6 floors.",
      },
      {
        title: "Residential Tower",
        url: "Residential-Tower",
        img: "/images/projects/projects-logo.webp",
        bgImg: "bg-[url('~/public/images/projects/projects-logo-small.png')]",
        partner: "Mr. Ahmed Elnabaroui.",
        year: "1998.",
        location: "Al-Mashaya St, Dakahlia Government.",
        description: "500 M<sup>2</sup>, Basement, Ground, with 11 floors.",
      },
      {
        title: "Residential tower",
        url: "Residential-tower",
        img: "/images/projects/projects-logo.webp",
        bgImg: "bg-[url('~/public/images/projects/projects-logo-small.png')]",
        partner: "Mr. Samy Aawad.",
        year: "1999.",
        location: "",
        description: "800 M<sup>2</sup>, Basement, Ground, with 11 floors.",
      },
      {
        title: "Residential Tower",
        url: "23-Residential-Tower",
        img: "/images/projects/projects-logo.webp",
        bgImg: "bg-[url('~/public/images/projects/projects-logo-small.png')]",
        partner: "Eng. Mahmoud Ammar.",
        year: "1997.",
        location: "El Gomhouria St, Dakahlia Government.",
        description: "500 M<sup>2</sup>, Basement, Ground with 11 floors.",
      },
      {
        title: "Residential tower",
        url: "Residential-tower",
        img: "/images/projects/projects-logo.webp",
        bgImg: "bg-[url('~/public/images/projects/projects-logo-small.png')]",
        partner: "Aasha Abdel-Aziz.",
        year: "2000.",
        location: "Nabil Al Wakkad, Al Golf, Nasr City, Cairo Government.",
        description: "500 M<sup>2</sup>, Basement, Ground, with 11 floors.",
      },
      {
        title: "The Palace and Iris Towers",
        url: "The-Palace-and-Iris-Towers",
        img: "/images/projects/projects-logo.webp",
        bgImg: "bg-[url('~/public/images/projects/projects-logo-small.png')]",
        partner: "Eng. Mahmoud Ammar.",
        year: "1998",
        location: "El Gomhouria St, Dakahlia Government.",
        description:
          "4000  M<sup>2</sup>, Basement, Ground, with 10 floors each floor has 10 apartments. ",
      },
      {
        title: "Residential tower",
        url: "Residential-tower",
        img: "/images/projects/projects-logo.webp",
        bgImg: "bg-[url('~/public/images/projects/projects-logo-small.png')]",
        partner: "Mr. Reda Ismail.",
        year: "1997",
        location: "Maadi, Cairo Government.",
        description: "1000  M<sup>2</sup>, Basement, Ground, with 10 floors",
      },
      {
        title: "KFC Residential tower",
        url: "KFC-Residential-tower",
        img: "/images/projects/projects-logo.webp",
        bgImg: "bg-[url('~/public/images/projects/projects-logo-small.png')]",
        partner: "Mostafa Ammar.",
        year: "1999",
        location: "El-Galaa, El Mansoura 1, Dakahlia Government.",
        description: "1000 M<sup>2</sup>, Basement, Ground, with 10 floors.",
      },
      {
        title: "Residential Tower",
        url: "28-Residential-Tower",
        img: "/images/projects/projects-logo.webp",
        bgImg: "bg-[url('~/public/images/projects/projects-logo-small.png')]",
        partner: "Mr. Mohamed El-basiouny.",
        year: "1996",
        location: "El Gomhouria St, Dakahlia Government.",
        description: "1000  M<sup>2</sup>, Basement, Ground, with 10 floors.",
      },
      {
        title: "The princes",
        url: "29-The-princes",
        img: "/images/projects/projects-logo.webp",
        bgImg: "bg-[url('~/public/images/projects/projects-logo-small.png')]",
        partner: "Eng. Mostafa Ammar.",
        year: "1996",
        location: "El Gomhouria St, Dakahlia Government.",
        description: "1000 M<sup>2</sup>, Basement, Ground, with 10 floors.",
      },
      {
        title: "Residential Tower",
        url: "30-Residential-Tower",
        img: "/images/projects/projects-logo.webp",
        bgImg: "bg-[url('~/public/images/projects/projects-logo-small.png')]",
        partner: "Mr. Hassan Ramdan.",
        year: "1994",
        location: "Wali Al Ahd, Hadaeq Al Qubbah, Cairo Government.",
        description: "1750 M<sup>2</sup>, Ground, with 11 floors.",
      },
    ],
  },
  {
    category: "Industrial",
    url: "industrial",
    img: "/images/projects/industrial.webp",
    bgImg: "bg-[url('~/public/images/projects/industrial-small.png')]",
    featured: false,
    projects: [
      {
        title: "International factories for furniture",
        url: "International-factories-for-furniture",
        img: "/images/projects/projects-logo.webp",
        bgImg: "bg-[url('~/public/images/projects/projects-logo-small.png')]",
        partner: "Mr. Salah Abdullah ",
        year: "2001",
        location: "El Basatin, Cairo Government.",
        description:
          "1500 Meters<sup>2</sup2, It’s from a concrete structure, with 6 floors.",
      },
      {
        title: "Samco Engineering and Medical Equipment",
        url: "Samco-Engineering-and-Medical-Equipment",
        img: "/images/projects/projects-logo.webp",
        bgImg: "bg-[url('~/public/images/projects/projects-logo-small.png')]",
        partner: "Eng. Mohamed Samk ",
        year: "1996",
        location:
          "Industrial Zone1, Al Salam First 2, Obour City, Cairo Government.",
        description:
          "3000 Meters<sup>2</sup>, It’s from a concrete structure, with 4 floors.",
      },
      {
        title: "Amer Factory For The Manufacture of glass",
        url: "Amer-Factory-For-The-Manufacture-of-glass",
        img: "/images/projects/projects-logo.webp",
        bgImg: "bg-[url('~/public/images/projects/projects-logo-small.png')]",
        partner: "Mr. Mostafa Amer",
        year: "2018",
        location: "Third Settlement, New Cairo, Cairo Government",
        description: "430 M<sup>2</sup>, Ground, 5 floors",
      },
      {
        title: "Dar Abi Hayan Press",
        url: "Dar-Abi-Hayan-Press",
        img: "/images/projects/projects-logo.webp",
        bgImg: "bg-[url('~/public/images/projects/projects-logo-small.png')]",
        partner: "Mr. Mohmed Hassan ",
        year: "1995",
        location: "Industrial Zone1, Badr, Cairo Government.",
        description:
          "3000 M<sup>2</sup>, It’s from a concrete structure, with 4 floors.",
      },
      {
        title: "Vortex Factory",
        url: "Vortex-Factory",
        img: "/images/projects/projects-logo.webp",
        bgImg: "bg-[url('~/public/images/projects/projects-logo-small.png')]",
        partner: "Mr. Mohamed Hassan ",
        year: "2000",
        location: "Industrial Zone1, Badr, Cairo Government.",
        description: "2000 M<sup>2</sup>It’s from a Metal Frame.",
      },
      {
        title: "Mistrec Sanitary Ware Factory",
        url: "Mistrec-Sanitary-Ware-Factory",
        img: "/images/projects/projects-logo.webp",
        bgImg: "bg-[url('~/public/images/projects/projects-logo-small.png')]",
        partner: "Mahgoub’s Sons. ",
        year: "2001",
        location: "10th of Ramadan City, Sharqia Government.",
        description:
          "6000 M<sup>2</sup>, It’s from a concrete structure, with 3 floors.",
      },
      {
        title: "Shaunah Al-Tabin shed",
        url: "Shaunah-Al-Tabin-shed",
        img: "/images/projects/projects-logo.webp",
        bgImg: "bg-[url('~/public/images/projects/projects-logo-small.png')]",
        partner: "Mills south of Cairo and Giza",
        year: "2001",
        location: "Al-Tabin, Helwan, Cairo Government.",
        description: "6000  M<sup>2</sup>, it consists of iron trusses.",
      },
      {
        title: "Electrical Cables And Wires Factory",
        url: "Electrical-Cables-And-Wires-Factory",
        img: "/images/projects/projects-logo.webp",
        bgImg: "bg-[url('~/public/images/projects/projects-logo-small.png')]",
        partner: "Fathy Hassan Companies ",
        year: "2007",
        location: "10th of Ramadan City, Sharqia Government.",
        description: "900  M<sup>2</sup>, Ground, 5 floors",
      },
      {
        title: "Shearing And Paper Converting Factory",
        url: "Shearing-And-Paper-Converting-Factory",
        img: "/images/projects/projects-logo.webp",
        bgImg: "bg-[url('~/public/images/projects/projects-logo-small.png')]",
        partner: "First Company For Industrial Development",
        year: "2009",
        location: "Obour City, Cairo Government.",
        description: "2500 M<sup>2</sup>, Ground, 5 floors",
      },
      {
        title: "Central workshop factory",
        url: "Central-workshop-factory",
        img: "/images/projects/projects-logo.webp",
        bgImg: "bg-[url('~/public/images/projects/projects-logo-small.png')]",
        partner: "First Company For Industrial Development",
        year: "2008",
        location: "Obour City, Cairo Government.",
        description: "3500  M<sup>2</sup>, Ground, 5 floors",
      },
      {
        title: "Mobco Fertilizer Factory",
        url: "Mobco-Fertilizer-Factory",
        img: "/images/projects/projects-logo.webp",
        bgImg: "bg-[url('~/public/images/projects/projects-logo-small.png')]",
        partner: "Contracting Company for Armed Cement",
        year: "2010",
        location: "Damietta Government.",
        description:
          "10000  M<sup>2</sup>, And a warehouse building with an area of 1500 M<sup>2</sup>",
      },
      {
        title: "Madbaa Factory",
        url: "Madbaa-Factory",
        img: "/images/projects/projects-logo.webp",
        bgImg: "bg-[url('~/public/images/projects/projects-logo-small.png')]",
        partner: "Eng. Magdy Nasef ",
        year: "2019",
        location: "10th of Ramadan City, Sharqia Government.",
        description:
          "10000 M<sup>2</sup>, Hanger Steel M<sup>2</sup>, and 2500 M<sup>2</sup> Warehouses.",
      },
      {
        title: "Sallab Ceramic",
        url: "Sallab-Ceramic",
        img: "/images/projects/projects-logo.webp",
        bgImg: "bg-[url('~/public/images/projects/projects-logo-small.png')]",
        partner: "Eng. Abdel-Aziz Al-Sallab",
        year: "2019",
        location: "10th of Ramadan City, Sharqia Government. ",
        description: "30000  M<sup>2</sup>, Structure of concrete and steel.",
      },
      {
        title: "Ware Houses",
        url: "Ware-Houses",
        img: "/images/projects/projects-logo.webp",
        bgImg: "bg-[url('~/public/images/projects/projects-logo-small.png')]",
        partner: "Mills East Delta",
        year: "2020",
        location: "Ismailia City, East Delta Mills.",
        description:
          "7000  M<sup>2</sup>, it consists of iron trusses, a scale, and an administrative building.",
      },
    ],
  },
  {
    category: "Mills",
    url: "mills",
    img: "/images/projects/mills.webp",
    bgImg: "bg-[url('~/public/images/projects/mills-small.png')]",
    featured: false,
    projects: [
      {
        title: "Imbaba Mills",
        url: "imbaba-mills",
        img: "/images/projects/projects-logo.webp",
        bgImg: "bg-[url('~/public/images/projects/projects-logo-small.png')]",
        partner: "Mills south of Cairo and Giza",
        year: "2010",
        location: "Imbaba, Giza Government. ",
        description: "Produce 350 tons per day.",
      },
      {
        title: "Disk Mail Mills",
        url: "disk-mail-mills",
        img: "/images/projects/projects-logo.webp",
        bgImg: "bg-[url('~/public/images/projects/projects-logo-small.png')]",
        partner: "Mills south of Cairo and Giza",
        year: "2002",
        location: "El Tebbin, Cairo Government. ",
        description: "Produce 450 tons per day",
      },
      {
        title: "Ezz Al-Din Al-Ramali Mills",
        url: "Ezz-Al-Din-Al-Ramali-Mills",
        img: "/images/projects/projects-logo.webp",
        bgImg: "bg-[url('~/public/images/projects/projects-logo-small.png')]",
        partner: "Mills south of Cairo and Giza",
        year: "2002",
        location: "Al-Sayeda Zainab, Cairo Government. ",
        description: "Produce 250 tons per day.",
      },
      {
        title: "Mohamed Abass Mills",
        url: "mohamed-abass-mills",
        img: "/images/projects/projects-logo.webp",
        bgImg: "bg-[url('~/public/images/projects/projects-logo-small.png')]",
        partner: "Mills south of Cairo and Giza",
        year: "2002",
        location: "Al-Sayeda Zainab, Cairo Government. ",
        description: "Produce 250 tons per day.",
      },
      {
        title: "Sandy Corn Mills",
        url: "sandy-corn-mills",
        img: "/images/projects/projects-logo.webp",
        bgImg: "bg-[url('~/public/images/projects/projects-logo-small.png')]",
        partner: "Mills south of Cairo and Giza",
        year: "2001",
        location: "Al-Sayeda Zainab, Cairo Government. ",
        description: "Produce 250 tons per day.",
      },
      {
        title: "Giza Mill",
        url: "giza-mill",
        img: "/images/projects/projects-logo.webp",
        bgImg: "bg-[url('~/public/images/projects/projects-logo-small.png')]",
        partner: "Eng. Omar Al-Hinii",
        year: "2005",
        location: "Minya Government",
        description: "Structure Design.",
      },
      {
        title: "Middle Eastern Mills",
        url: "middle-eastern-mills",
        img: "/images/projects/projects-logo.webp",
        bgImg: "bg-[url('~/public/images/projects/projects-logo-small.png')]",
        partner: "Middle East Co.",
        year: "2005",
        location: "Belbeis City, Sharqia Government.",
        description: "Produce 250 tons per day",
      },
      {
        title: "German Imbaba Mill South Cairo",
        url: "german-imbaba-mill-south-cairo",
        img: "/images/projects/projects-logo.webp",
        bgImg: "bg-[url('~/public/images/projects/projects-logo-small.png')]",
        partner: "Mills south of Cairo and Giza",
        year: "2001",
        location: "Imbaba, Giza Government. ",
        description: "Produce 250 tons per day.",
      },
      {
        title: "Al Suwaihy Mill South Cairo",
        url: "al-suwaihy-mill-south-cairo",
        img: "/images/projects/projects-logo.webp",
        bgImg: "bg-[url('~/public/images/projects/projects-logo-small.png')]",
        partner: "Mills south of Cairo and Giza",
        year: "2010",
        location: "Giza Government. ",
        description: "Produce 250 tons per day.",
      },
      {
        title: "Shunat Al-Amriya Shed warehouses for wheat",
        url: "shunat-al-amriya-shed-warehouses-for-wheat",
        img: "/images/projects/projects-logo.webp",
        bgImg: "bg-[url('~/public/images/projects/projects-logo-small.png')]",
        partner: "Mills south of Cairo and Giza",
        year: "2000",
        location: "Amreya, Alexandria government",
        description:
          "7000 M<sup>2</sup>, it consists of iron trusses, a scale, and an administrative building.",
      },
    ],
  },
  {
    category: "Water Stations",
    url: "water-stations",
    img: "/images/projects/waterstation.webp",
    bgImg: "bg-[url('~/public/images/projects/waterstation-small.png')]",
    featured: false,
    projects: [
      {
        title: "Water Station",
        url: "Water-Station",
        img: "/images/projects/projects-logo.webp",
        bgImg: "bg-[url('~/public/images/projects/projects-logo-small.png')]",
        partner: "Housing Administration in Kafr El-Sheikh",
        year: "2000",
        location: "Qilin, Kafr Elsheikh Government",
        description: "Structure Design.",
      },
    ],
  },
  {
    category: "Education",
    url: "education",
    img: "/images/projects/Education.webp",
    bgImg: "bg-[url('~/public/images/projects/education-small.png')]",
    featured: false,
    projects: [
      {
        title: "Alsun Language School",
        url: "Alsun-Language-School",
        img: "/images/projects/projects-logo.webp",
        bgImg: "bg-[url('~/public/images/projects/projects-logo-small.png')]",
        partner: "Mr. Hassan Al-Azazi",
        year: "1995",
        location: "Eighth district, Nasr City, Cairo Government.",
        description: "Ground, and 4 floors.",
      },
      {
        title: "Al-Qanater Al-Khairiya Schools",
        url: "Al-Qanater-Al-Khairiya-Schools",
        img: "/images/projects/projects-logo.webp",
        bgImg: "bg-[url('~/public/images/projects/projects-logo-small.png')]",
        partner: "Ministry Of Higher Education",
        year: "1993",
        location: "Al Qanatir Al Khayriyyah, Cairo Government.",
        description:
          "After the 1992 earthquake, All Al-Qanater Al-Khairiya schools had been inspected, and provided solutions to repair them, which numbered 34 schools.",
      },
      {
        title: "Qalyub Schools",
        url: "Qalyub-Schools",
        img: "/images/projects/projects-logo.webp",
        bgImg: "bg-[url('~/public/images/projects/projects-logo-small.png')]",
        partner:
          "Ministry Of Higher Educationdarat el iskan feh kafr el shekiekh",
        year: "1993",
        location: "Qalyub, Qalyubia Government.",
        description:
          "After the 1992 earthquake, Some Qalyub Schools had been inspected and provided solutions to repair them, which numbered 18 schools.",
      },
      {
        title: "Cairo and Giza Schools",
        url: "Cairo-and-Giza-Schools",
        img: "/images/projects/projects-logo.webp",
        bgImg: "bg-[url('~/public/images/projects/projects-logo-small.png')]",
        partner: "Ministry Of Higher Education",
        year: "1993",
        location: "Cairo Government, and Giza Government.",
        description:
          "After the 1992 earthquake, Some of Cairo and Giza Schools had been inspected and provided solutions to repair them, which numbered 12 schools.",
      },
      {
        title: "Europa Schule Kairo",
        url: "europa-schule-kairo",
        img: "/images/projects/europa-schule-kairo.webp",
        bgImg:
          "bg-[url('~/public/images/projects/europa-schule-kairo-small.png')]",
        partner: "Dr. Gamal Nuaaruh",
        year: "2017",
        location: "Fifth Settlement, New Cairo, Cairo government.",
        description:
          "12000 Meter2, it consists of five educational buildings, a social entertainment building for the theater, and a swimming pool.",
      },
      {
        title: "Pack Line Formal Language School",
        url: "pack-line-formal-language-school",
        img: "/images/projects/pack-line-formal-language-school.webp",
        bgImg:
          "bg-[url('~/public/images/projects/pack-line-formal-language-school-small.png')]",
        partner: "First Company For Industrial Development",
        year: "2009",
        location: "First District, Obour City, Cairo Government.",
        description: " 4500 Meter2, Ground, and 3 floors.",
      },
      {
        title: "Al Azhar Institution Sector",
        url: "al-azhar-onstitution-sector",
        img: "/images/projects/al-azhar-onstitution-sector.webp",
        bgImg:
          "bg-[url('~/public/images/projects/al-azhar-onstitution-sector-small.png')]",
        partner: "Military Production.",
        year: "2018.",
        location: "Giza Government.",
        description:
          "Upgrading the efficiency of 10 Azhar Institution buildings, including reinforcing and repairing works.",
      },
      {
        title: "Bush Al Azhari Institution Sector",
        url: "Bush-Al-Azhari-Institution-Sector",
        img: "/images/projects/projects-logo.webp",
        bgImg: "bg-[url('~/public/images/projects/projects-logo-small.png')]",
        partner: "Military Production.",
        year: "2018",
        location: "Beni Suef Government.",
        description:
          "500 M<sup>2</sup>, Designing and supervising the project, which consists of Ground, 4 floors.",
      },
      {
        title: "Atfih Al Azhari Institution Sector",
        url: "Atfih-Al-Azhari-Institution-Sector",
        img: "/images/projects/projects-logo.webp",
        bgImg: "bg-[url('~/public/images/projects/projects-logo-small.png')]",
        partner: "Military Production.",
        year: "2018.",
        location: "Al Ayyat City, Giza Government.",
        description:
          "400 M<sup>2</sup>, Designing and supervising the project, which consists of Ground, 4 floors.",
      },
      {
        title: "Almajzar Alaali Institution Sector",
        url: "Almajzar-Alaali-Institution-Sector",
        img: "/images/projects/projects-logo.webp",
        bgImg: "bg-[url('~/public/images/projects/projects-logo-small.png')]",
        partner: "Military Production.",
        year: "2019.",
        location: "Beni Suef Government.",
        description:
          "350 M<sup>2</sup>, supervising the project, which consists of Ground, 4 floors.",
      },
    ],
  },
  {
    category: "Infrastructure",
    url: "infrastructure",
    img: "/images/projects/infrastructure.webp",
    bgImg: "bg-[url('~/public/images/projects/infrastructure-small.png')]",
    featured: false,
    projects: [
      {
        title: "Abu Hummus bridge",
        url: "abu-hummus-bridge",
        img: "/images/projects/projects-logo.webp",
        bgImg: "bg-[url('~/public/images/projects/projects-logo-small.png')]",
        partner: "Reinforced Cement Company",
        year: "2005",
        location: "Highway Cairo- Alexandria Agriculture Rd",
      },
      {
        title: "15th May Tunnel",
        url: "15th-may-tunnel",
        img: "/images/projects/15th-may-tunnel.webp",
        bgImg: "bg-[url('~/public/images/projects/15th-may-tunnel-small.png')]",
        partner: "Gharably Integrated Engineering Company",
        year: "2018",
        location: "2nd Ring Road, Cairo Government.",
        description: "Supervision on construction.",
      },
      {
        title: "Al-Sayr Tunnel",
        url: "al-sayr-tunnel",
        img: "/images/projects/al-sayr-tunnel.webp",
        bgImg: "bg-[url('~/public/images/projects/al-sayr-tunnel-small.png')]",
        partner: "Gharably Integrated Engineering Company",
        year: "2018",
        location: "2nd Ring Road, Cairo Government.",
        description: "Supervision on construction.",
      },
      {
        title: "Helwan Tunnel",
        url: "helwan-tunnel",
        img: "/images/projects/helwan-tunnel.webp",
        bgImg: "bg-[url('~/public/images/projects/helwan-tunnel-small.png')]",
        partner: "AGR International.",
        year: "2018",
        location: "2nd Ring Road, Cairo Government.",
        description: "Supervision on construction.",
      },
    ],
  },
  {
    category: "Healthcare",
    url: "healthcare",
    img: "/images/projects/healthcare.webp",
    bgImg: "bg-[url('~/public/images/projects/healthcare-small.png')]",
    featured: false,
    projects: [
      {
        title: "General Authority For Health Insurance",
        url: "General-Authority-For-Health-Insurance",
        img: "/images/projects/projects-logo.webp",
        bgImg: "bg-[url('~/public/images/projects/projects-logo-small.png')]",
        partner: "General Authority For Health Insurance",
        year: "2010",
        location: "Kafr Saqr, Sharqia Government.",
        description: "2000 M<sup>2</sup>, consists of a Ground, and 3 floors.",
      },
      {
        title: "Kidney Hospital",
        url: "kidney-hospital",
        img: "/images/projects/kidney-hospital.webp",
        bgImg: "bg-[url('~/public/images/projects/kidney-hospital-small.png')]",
        partner: "Tanta University",
        year: "2019",
        location: "Tanta City, Gharbia Government.",
        description:
          "2000 M<sup>2</sup>, consists of a Ground, and 4  floors, and it costs about 200,000,000 EGP.",
      },
    ],
  },
  {
    category: "Private Villas",
    url: "private-villas",
    img: "/images/projects/privet-villa.webp",
    bgImg: "bg-[url('~/public/images/projects/privet-villa-small.png')]",
    featured: false,
    projects: [
      {
        title: "Residential Villa",
        url: "Residential-Villa",
        img: "/images/projects/projects-logo.webp",
        bgImg: "bg-[url('~/public/images/projects/projects-logo-small.png')]",
        partner: "Doctor Gamal Noarah.",
        year: "2000",
        location:
          "Vanguard Agricultural Cooperative Society, Ismailia Road, Cairo Government.",
        description:
          "300 Meter2, Basement, Ground, with 2 floors, and swimming pool.",
      },
      {
        title: "Residential Villa",
        url: "Residential-Villa",
        img: "/images/projects/projects-logo.webp",
        bgImg: "bg-[url('~/public/images/projects/projects-logo-small.png')]",
        partner: "Doctor Mohamed Hany.",
        year: "2001",
        location: "Jasmine 3, First settlement, New Cairo, Cairo government.",
        description:
          "360 M<sup>2</sup>, Basement, Ground, with 2 floors, and swimming pool.",
      },
      {
        title: "Residential Villa",
        url: "Residential-Villa",
        img: "/images/projects/projects-logo.webp",
        bgImg: "bg-[url('~/public/images/projects/projects-logo-small.png')]",
        partner: "Mr. Atef alqnawy",
        year: "2001",
        location: "Golf, Fifth settlement, New Cairo, Cairo Government.",
        description:
          "800 M<sup>2</sup>, Basement, Ground, with 2 floors, and swimming pool.",
      },
      {
        title: "Resdiential Villa",
        url: "Resdiential-Villa",
        img: "/images/projects/projects-logo.webp",
        bgImg: "bg-[url('~/public/images/projects/projects-logo-small.png')]",
        partner: "Mr. Gamal Maaod",
        year: "2002",
        location: "Jasmin 2, First settlement, New Cairo, Cairo Government.",
        description: "300 M<sup>2</sup>, Basement, Ground, with 2 floors.",
      },
      {
        title: "Resdiential Villa",
        url: "Resdiential-Villa",
        img: "/images/projects/projects-logo.webp",
        bgImg: "bg-[url('~/public/images/projects/projects-logo-small.png')]",
        partner: "Mr. Gamal Maaod",
        year: "2002",
        location: "Jasmin 2, First settlement, New Cairo, Cairo Government.",
        description: "300 M<sup>2</sup>, Basement, Ground, with 2 floors.",
      },
    ],
  },
];
