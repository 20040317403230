import { useEffect, React } from "react";

export default function BlurLoad({ img, div, dependancy = null }) {
  useEffect(() => {
    const blurLoad = document.querySelectorAll(".blur-load");
    blurLoad.forEach((e) => {
      const img = e.querySelector(".blur-load>img");

      function loadded() {
        e.classList.add("loadded");
      }

      img.complete ? loadded() : img.addEventListener("load", loadded);
    });
  }, [dependancy]);
  return (
    <div
      className={div.cls}
      data-aos={div?.aos?.type}
      data-aos-delay={div?.aos?.delay}
      data-aos-duration={div?.aos?.duration}
      data-aos-easing={div?.aos?.easing}
    >
      <img src={img.src} alt={img.alt} className={img.cls} loading="lazy" />
    </div>
  );
}
