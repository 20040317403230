import React, { useRef } from "react";
import emailjs from "@emailjs/browser";
import { useFormik } from "formik";
import * as Yup from "yup";
import { BsFillTelephoneFill } from "react-icons/bs";
import { TbDeviceLandlinePhone } from "react-icons/tb";
import { HiMail } from "react-icons/hi";
import { toast } from "react-toastify";

function Contact() {
  const formValues = useRef();

  const notify = () => {
    toast.success("Message sent", {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      message: "",
      subject: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required(),
      email: Yup.string().email("Invalid email").required(),
      subject: Yup.string().required(),
      message: Yup.string()
        .min(10, "Too Short!")
        .max(100, "Too Long!")
        .required("Field can't be empty"),
    }),
    onSubmit: () => {
      handleSubmit();
    },
  });

  const handleSubmit = () => {
    emailjs
      .sendForm(
        "service_mfls2ox",
        "template_ex7odzn",
        formValues.current,
        "5DHwySEm2VWKEwGXF"
      )
      .then(() => {
        notify();
        formik.resetForm();
      });
  };

  return (
    <section>
      <div className="text-gray-800">
        <div className="container px-5 py-10 max-md:pb-0 mx-auto">
          <div className="flex justify-center">
            <div className="text-center lg:max-w-3xl md:max-w-xl">
              <h2 className="text-center uppercase font-bold text-[clamp(2rem,3.5vw,4rem)] mb-[20px] font-[Aquarium]">
                Contact us
              </h2>
            </div>
          </div>
          <div className="flex flex-wrap">
            <div className="grow-0 shrink-0 basis-auto mb-12 lg:mb-0 w-full lg:w-5/12 px-3 lg:px-6">
              <form ref={formValues} onSubmit={formik.handleSubmit}>
                <div className="form-group mb-6">
                  <input
                    type="text"
                    className="form-control block w-full px-3 py-2.5  text-base  text-gray-700 bg-white bg-clip-padding  border border-solid border-gray-300 rounded transition m-0 focus:text-gray-700  focus:border-[#f5cc00]  focus:ring-0 outline-none"
                    placeholder="Name"
                    name="name"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.name}
                  />
                  <p className="text-red-500 text-xs italic mt-1">
                    {formik.touched.name && formik.errors.name}
                  </p>
                </div>
                <div className="form-group mb-6">
                  <input
                    className="form-control block w-full px-3 py-2.5  text-base  text-gray-700 bg-white bg-clip-padding  border border-solid border-gray-300 rounded transition m-0 focus:text-gray-700 focus:border-[#f5cc00] focus:ring-0  outline-none"
                    name="email"
                    placeholder="Email"
                    type="email"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                  />
                  <p className="text-red-500 text-xs italic mt-1">
                    {formik.touched.email && formik.errors.email}
                  </p>
                </div>
                <div className="form-group mb-6">
                  <input
                    className="form-control block w-full px-3 py-2.5  text-base  text-gray-700 bg-white bg-clip-padding  border border-solid border-gray-300 rounded transition m-0 focus:text-gray-700 focus:border-[#f5cc00] focus:ring-0  outline-none"
                    name="subject"
                    placeholder="Subject"
                    type="text"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.subject}
                  />
                  <p className="text-red-500 text-xs italic mt-1">
                    {formik.touched.email && formik.errors.email}
                  </p>
                </div>
                <div className="form-group mb-6">
                  <textarea
                    className="form-control block w-full px-3 py-2.5  text-base  text-gray-700 bg-white bg-clip-padding  border border-solid border-gray-300 rounded transition m-0 focus:text-gray-700  focus:border-[#f5cc00] focus:ring-0  outline-none"
                    id="exampleFormControlTextarea13"
                    name="message"
                    placeholder="Message"
                    rows="6"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.message}
                  />
                  <p className="text-red-500 text-xs italic mt-1">
                    {formik.touched.message && formik.errors.message}
                  </p>
                </div>
                <button
                  type="submit"
                  className="w-full px-6 py-2.5 bg-[#24262b] text-white font-semibold text-md leading-tight capitalize rounded shadow-md hover:bg-[#1d1d1d] hover:shadow-lg focus:bg-[#1d1d1d] focus:shadow-lg focus:outline-none focus:ring-0 active:bg-[#1d1d1d] active:shadow-lg transition duration-150"
                >
                  send
                </button>
              </form>
            </div>
            <div className="grow-0 shrink-0 basis-auto w-full lg:w-7/12">
              <div className="flex flex-wrap">
                <div className="mb-12 grow-0 shrink-0 basis-auto w-full lg:w-6/12 px-3 lg:px-6">
                  <div className="flex items-start">
                    <div className="shrink-0">
                      <div className="p-4 bg-[#24262b] rounded-md shadow-md w-14 h-14 flex items-center justify-center text-white text-xl">
                        <BsFillTelephoneFill />
                      </div>
                    </div>
                    <div className="grow ml-6">
                      <p className="font-bold mb-1">Mobile</p>
                      <p className="text-gray-500">+201210276274</p>
                    </div>
                  </div>
                </div>
                <div className="mb-12 grow-0 shrink-0 basis-auto w-full lg:w-6/12 px-3 lg:px-6">
                  <div className="flex items-start">
                    <div className="shrink-0">
                      <div className="p-4 bg-[#24262b] rounded-md shadow-md w-14 h-14 flex items-center justify-center text-white text-2xl">
                        <TbDeviceLandlinePhone />
                      </div>
                    </div>
                    <div className="grow ml-6">
                      <p className="font-bold mb-1">Landline & Fax</p>
                      <p className="text-gray-500">0222906226</p>
                    </div>
                  </div>
                </div>
                <div className="mb-12 grow-0 shrink-0 basis-auto w-full lg:w-6/12 px-3 lg:px-6">
                  <div className="flex align-start">
                    <div className="shrink-0">
                      <div className="p-4 bg-[#24262b] rounded-md shadow-md w-14 h-14 flex items-center justify-center text-white text-3xl">
                        <HiMail />
                      </div>
                    </div>
                    <div className="grow ml-6">
                      <p className="font-bold mb-1">Email</p>
                      <p className="text-gray-500">Drhamdyshehab@outlook.com</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <iframe
        title="location"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3452.213540819072!2d31.3125869258022!3d30.08807007490167!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14583fedfdd085d1%3A0x9b8a893c1dab2a78!2sConsultant%20engineering%20office%20DR%2FHamdy%20Shehab!5e0!3m2!1sar!2seg!4v1687210383684!5m2!1sar!2seg"
        width="200"
        height="200"
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
        className="w-[100%] h-[300px]"
      />
    </section>
  );
}

export default Contact;
