/* eslint-disable jsx-a11y/anchor-is-valid */
import { React, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { PROJECTS } from "../constant/Projects";
import { SlCalender } from "react-icons/sl";
import { BsInfoLg } from "react-icons/bs";
import { GrLocation } from "react-icons/gr";
import { MdOutlineHandshake } from "react-icons/md";
import Slider from "react-slick";
import { BlurLoad } from "../components";

function ProjectDetails() {
  const [project, setProject] = useState({});
  const [isFeatured, setIsFeatured] = useState(false);
  const { category, title } = useParams();

  useEffect(() => {
    let tempProjects = PROJECTS.find((project) => project.url === category);
    setIsFeatured(tempProjects.featured);
    setProject(
      tempProjects.projects.find(
        (project) =>
          project.url.toLowerCase().trim() === title.toLowerCase().trim()
      )
    );
  }, [category, title]);

  if (!project) return null;

  return (
    <section className="text-center md:text-left py-12">
      <div className="container flex items-center mx-auto justify-evenly flex-wrap gap-5">
        {typeof project.img === "object" ? (
          <div className="w-[600px] max-sm:w-[300px]">
            <Slider
              slidesToScroll={1}
              slidesToShow={1}
              speed={500}
              infinite={true}
              dots={true}
              arrows={false}
              autoplay={true}
              autoplaySpeed={3000}
              dotsClass="slick-dots slick-thumb"
              customPaging={(i) => (
                <a>
                  <img
                    src={`/images/projects/toshka0${i + 1}.webp`}
                    alt="toshka"
                  />
                </a>
              )}
            >
              {project.img.map((img, i) => (
                <BlurLoad
                  key={i}
                  div={{
                    cls: `blur-load ${project.bgImg[i]}  sm:h-[350px]  sm:mb-2 rounded-[20px_0]`,
                  }}
                  img={{
                    src: img,
                    alt: "toshka",
                    cls: "rounded-[20px_0] h-full",
                  }}
                  dependancy={project}
                />
              ))}
            </Slider>
          </div>
        ) : (
          <BlurLoad
            div={{
              cls: `blur-load rounded-[20px_0] sm:h-[500px] h-[400px] sm:w-[400px] w-[300px] ${project.bgImg}`,
              aos: { type: "fade-right" },
            }}
            img={{
              cls: "rounded-[20px_0] h-full",
              alt: project.title,
              src: project.img,
            }}
            dependancy={project}
          />
        )}
        <div className="w-[500px] flex flex-col sm:mt-10 px-2">
          <h2 className="md:text-2xl text-xl font-bold">{project.title}</h2>
          <ul className="my-2 flex flex-col gap-5 p-2">
            {project?.description && (
              <li className="flex items-center gap-4">
                <div>
                  <BsInfoLg className="text-[30px]" />
                </div>
                {!isFeatured ? (
                  <p
                    className="text-gray-500 text-[15px] md:text-[18px] text-left"
                    dangerouslySetInnerHTML={{ __html: project.description }}
                  />
                ) : (
                  <div>
                    <p className="text-black font-bold text-[18px] text-left">
                      {project.description?.info}
                    </p>
                    <ul className="flex gap-2 flex-col text-gray-500 font-normal mt-2 text-left">
                      {project.description?.subInfo.map((item, index) => (
                        <li key={index}>{item}</li>
                      ))}
                    </ul>
                    <a
                      href="https://docs.google.com/presentation/d/1hs6wffsvBEN4ECYIFs9z9Sdi9J3CfAIY/edit#slide=id.p4"
                      target="_blank"
                      rel="noreferrer"
                      className="text-center font-semibold hover:bg-black hover:text-white uppercase mx-auto block rounded-lg border-2 border-black w-32 py-2 mt-6 transition-all"
                    >
                      Read more
                    </a>
                  </div>
                )}
              </li>
            )}
            {project?.partner && (
              <li className="flex items-center gap-4">
                <div>
                  <MdOutlineHandshake className="text-[30px] " />
                </div>
                <p className="text-gray-500 text-[15px] md:text-[18px]">
                  {project?.partner}
                </p>
              </li>
            )}
            {project?.year && (
              <li className="flex items-center gap-4">
                <div>
                  <SlCalender className="text-[30px] " />
                </div>
                <p className="text-gray-500 text-[15px] md:text-[18px]">
                  {project.year}
                </p>
              </li>
            )}
            {project?.location && (
              <li className="flex items-center gap-4">
                <div>
                  <GrLocation className="text-[30px] " />
                </div>
                <p className="text-gray-500 text-left text-[15px] md:text-[18px]">
                  {project.location}
                </p>
              </li>
            )}
          </ul>
        </div>
      </div>
    </section>
  );
}

export default ProjectDetails;
